<template>
  <router-view></router-view>
</template>


<script>
// import TableMembership from "@/components/tables/TableMembership.vue"
export default {
  name: "dataIndex",
  // components : {TableMembership},
  data: () => ({}),
};
</script>

<style>
</style>